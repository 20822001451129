<template>
    <div class="text-center">
        <template v-if="checkPermission('TindakanResource.PUT.Tindakan')">
        <button
            @click="editTindakan()"
            class="btn btn-sm btn-clean btn-icon btn-icon-md"
            title="Edit"
        >
            <i class="la la-pencil-square"></i>
        </button>
        </template>
        <template v-if="checkPermission('TindakanResource.DELETE.Tindakan.id')">
        <button
            @click="deleteTindakan()"
            class="btn btn-sm btn-clean btn-icon btn-icon-md"
            title="Hapus"
        >
            <i class="la la-trash"></i>
        </button>
        </template>
    </div>
</template>

<script>
import CheckPermission from "../../../services/checkPermission.service";
import { RepositoryFactory } from "../../../repositories/RepositoryFactory";
import SweetAlertTemplate from "./../../_general/SweetAlert";
import BlockUI from "../../_js/BlockUI";
const blockUI = new BlockUI();
const TindakanRepository = RepositoryFactory.get("tindakan");
const checkPermission = new CheckPermission();
const SweetAlert = Vue.extend(SweetAlertTemplate);
export default {
     data() {
        return {
            showSpinner: false
        }
    },
    props: {
        tindakanVue: { type: Object },
        rowData: { type: Object }
    },
    methods: {
        deleteTindakan() {
            var vx = this;
            let instance = new SweetAlert().showConfirm({
                swalTitle: "Apakah Anda yakin?",
                swalText: "Data Tindakan akan terhapus.",
                swalType: "warning",
                onConfirmButton: function () {
                    blockUI.blockPage();
                    vx.delete();
                },
                onCancelButton: function () {
                    blockUI.unblockPage();
                }
            });
        },
        async delete() {
            var vx = this;

            await TindakanRepository.deleteTindakan(this.rowData.id)
                .then(response => {
                    new Promise((resolve, reject) => {
                        vx.tindakanVue.fetch(resolve);
                    }).then((response) => {
                        let instance = new SweetAlert().showInfo({
                        swalTitle: "Berhasil!",
                        swalText: "Data Tindakan berhasil terhapus.",
                        swalType: "success",
                        onConfirmButton: function () {
                            blockUI.unblockPage();
                        }
                        });
                    });
                })
                .catch(error => {
                    let instance = new SweetAlert().showInfo({
                        swalTitle: "Gagal!",
                        swalText: "Data Tindakan gagal terhapus.",
                        swalType: "error",
                        onConfirmButton: function () {
                            blockUI.unblockPage();
                        }
                    });
                });
            },
        editTindakan() {
            blockUI.blockPage();
            TindakanRepository.getTindakan(this.rowData.id)
            .then(res => {
                blockUI.unblockPage();
                this.tindakanVue.dataEdit = res.data;
                this.tindakanVue.dataPlus = null;
                this.tindakanVue.showModal();
            })
            .catch(err => {
                blockUI.unblockPage();
                this.showToastError('Tindakan : ' + err.error.response.data);
            })
        },
        checkPermission: function (method) {
            return checkPermission.isCanAccess(method);
        },
        showToastError: function(message) {
            toastr.error(message);
        },
    }
}
</script>

<style>

</style>
