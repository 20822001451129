<template>
  <table
    class="table table-striped- table-bordered table-hover table-checkable"
    v-bind:id="table.tableName"
  ></table>
</template>

<script>
import CheckPermission from "../../../services/checkPermission.service";
const checkPermission = new CheckPermission();
import { RepositoryFactory } from "../../../repositories/RepositoryFactory";
const TindakanRepository = RepositoryFactory.get("tindakan");
import BlockUI from "../../_js/BlockUI";
const blockUI = new BlockUI();
export default {
  props: {
    table: { type: Object },
    tindakanVue: { type: Object }
  },

  methods: {
    showToastError: function(message) {
        toastr.error(message);
    },
    getData(id) {
      blockUI.blockPage();
      TindakanRepository.getTindakan(id)
      .then(res => {
        blockUI.unblockPage();
        this.tindakanVue.dataEdit = res.data;
        this.tindakanVue.dataPlus = null;
        this.tindakanVue.showModal();
      })
      .catch(err => {
          blockUI.unblockPage();
          this.showToastError('Tindakan : ' + err.error.response.data);
      })
    },
    openModal(id,nama) {
        this.tindakanVue.dataEdit = null;
        this.tindakanVue.dataPlus = {
            id: id,
            nama: nama
        }
        this.tindakanVue.showModal();
    },
    getDatatables: function() {
      var vx = this;
      var tableParams = this.table;

      $.fn.dataTable.Api.register("column().title()", function() {
        return $(this.header())
          .text()
          .trim();
      });

      var dtTables, settingsOInstance;

      dtTables = $("#" + tableParams.tableName).DataTable({
        responsive: !0,
        dom:
          "<'row'<'col-sm-12'tr>><'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7 dataTables_pager'lp>>",
        lengthMenu: [5, 10, 25, 50],
        pageLength: 10,
        language: {
          lengthMenu: "Menampilkan _MENU_",
          info: "Menampilkan _START_ - _END_ dari _TOTAL_ hasil"
        },
        searchDelay: 500,
        processing: !0,
        serverSide: 0,
        order: [[0, "desc"]],
        data: tableParams.tableDataResult,
        columns: tableParams.tableColumns,
        initComplete: function(settings) {
          var e = this;

          settingsOInstance = settings.oInstance;

          $("#table_tindakan").on("click", "[data-delete-id]", function() {
            var del_id = $(this).attr("data-delete-id");
            swal({
              title: "Apakah Anda yakin?",
              text:
                "Data Tindakan dan semua sub yang bersangkutan akan terhapus.",
              type: "warning",
              showCancelButton: true,
              confirmButtonText: "Delete"
            }).then(function(result) {
              if (result.value) {
               TindakanRepository.deleteTindakan(del_id)
                  .then(
                    response => {
                      swal({
                        title: "Terhapus!",
                        text: "Data Tindakan berhasil terhapus.",
                        type: "success"
                      }).then(reload => {
                        var table = $("#table_tindakan").DataTable();
                        vx.tindakanVue.fetch();
                      });
                    },
                    error => {
                      swal(
                        "Gagal!",
                        "Data Tindakan tidak berhasil dihapus.",
                        "error"
                      );
                    }
                  );
              }
            });
          });

          $("#table_tindakan").on("click", "[data-edit-id]", function() {
            var tindakan_id = $(this).attr("data-edit-id");
            vx.getData(tindakan_id);
          });
          $("#table_tindakan").on("click", "[data-plus-id]", function() {
            var tindakan_id = $(this).attr("data-plus-id");
            var tindakan_nama = $(this).attr("data-plus-nama");
            vx.openModal(tindakan_id,tindakan_nama);
          });
        },
        drawCallback: function(settings) {
          var api = this.api();
          var rows = api
            .rows({
              page: "all"
            })
            .nodes();
          var last = null;

          api
            .column(0, {
              page: "all"
            })
            .data()
            .order([1, "desc"],)
            .each(function(group, i) {
              if (last !== group) {
                var plusSub = "";
                var showEdit = "";
                var showDelete = "";
                if (vx.checkPermission("TindakanResource.POST.Tindakan")) {
                  plusSub =
                    '<button data-plus-id="' +
                    api.column(1).data()[i] +
                    '"  data-plus-nama="'+api.column(0).data()[i]+'" class="dropdown-item">\
                        <i class="la la-plus"></i> Tambah Sub Tindakan\
                        </button>';
                }
                if (vx.checkPermission("TindakanResource.PUT.Tindakan")) {
                  showEdit =
                    '<button data-edit-id="' +
                    api.column(1).data()[i] +
                    '" class="dropdown-item">\
                        <i class="la la-edit"></i> Edit Tindakan\
                        </button>';
                }

                if (vx.checkPermission("TindakanResource.DELETE.Tindakan.id")) {
                  showDelete =
                    '<button data-delete-id="' +
                    api.column(1).data()[i] +
                    '" class="dropdown-item">\
                        <i class="la la-trash"></i> Hapus Tindakan\
                        </button>';
                }

                $(rows)
                  .eq(i)
                  .before(
                    '<tr class="group">\
                      <td colspan="5">' +
                      group +
                      '&nbsp;&nbsp;\
                          <div class="dropdown d-inline">\
                            <button\
                                class="btn btn-sm btn-clean btn-icon btn-icon-md"\
                                data-toggle="dropdown">\
                            <i class="la la-ellipsis-h"></i>\
                            </button>\
                            <div class="dropdown-menu dropdown-menu-left">\
                                <h6 class="dropdown-header">Option</h6>' +
                        showEdit + plusSub + showDelete +
                      '</div> </div>\
                      </td>\
                      </tr>'
                  );
                last = group;
              }
              if(!api.column(2).data()[i]){
                 $(rows)
                  .eq(i).hide();
              }
            });
        },
        columnDefs: tableParams.tableColumnDefs
      });

      vx.dataTableFilter(dtTables, settingsOInstance);
    },
    dataTableFilter: function(t, e) {
      var vx = this;
      var tableParams = this.table;

      var a = $('<tr class="filter"></tr>').appendTo($(t.table().header()));
      e.api()
        .columns()
        .every(function() {
          var fx = this;

          var e;

          var column_type;
          var column_type2 = _.first(
            _.filter(tableParams.tableFilter, function(data) {
              return data.title == fx.title();
            })
          );

          if (column_type2 != undefined) {
            column_type = column_type2.type;
          }

          switch (column_type) {
            case "text":
              e = $(
                '<input type="text" class="form-control form-control-sm form-filter kt-input" data-col-index="' +
                  this.index() +
                  '"/>'
              );
              break;
            case "combobox":
              (e = $(
                '<select class="form-control form-control-sm form-filter kt-input" title="Select" data-col-index="' +
                  this.index() +
                  '"><option value="">Semua</option></select>'
              )),
                this.data()
                  .unique()
                  .sort()
                  .each(function(t, a) {
                    $(e).append('<option value="' + t + '">' + t + "</option>");
                  });
              break;
            case "datepicker_today":
              e = $(
                '<div class="input-group input-group-sm date">\
                                                     <div class="input-group-prepend"><span class="input-group-text"><i class="la la-calendar-o glyphicon-th"></i></span></div>\
                                                     <input type="text" class="form-control form-control-sm form-filter kt-input datepicker-today-filter" data-col-index="' +
                  this.index() +
                  '">\
                                                     </div>'
              );
              break;
            case "datepicker":
              e = $(
                '<div class="input-group input-group-sm date">\
                                                     <div class="input-group-prepend"><span class="input-group-text"><i class="la la-calendar-o glyphicon-th"></i></span></div>\
                                                     <input type="text" class="form-control form-control-sm form-filter kt-input datepicker-filter" data-col-index="' +
                  this.index() +
                  '">\
                                                     </div>'
              );
              break;
            case "actions":
              var i = $(
                  '<div class="btn-group mr-2 d-none" role="group">\
                                                     <button id="filter_search" class="btn btn-brand kt-btn btn-sm kt-btn--icon" title="Search"><span><i class="la la-search"></i></span></button>\
                                                     </div>'
                ),
                s = $(
                  '<div class="btn-group d-none" role="group">\
                                                         <button class="btn btn-secondary kt-btn btn-sm kt-btn--icon" title="Reset"><span><i class="la la-close"></i></span></button>\
                                                         </div>'
                );
              $("<th>")
                .append(i)
                .append(s)
                .appendTo(a),
                $(i).on("click", function(e) {
                  e.preventDefault();
                  var n = {};
                  $(a)
                    .find(".kt-input")
                    .each(function() {
                      var t = $(this).data("col-index");
                      n[t]
                        ? (n[t] += "|" + $(this).val())
                        : (n[t] = $(this).val());
                    }),
                    $.each(n, function(e, a) {
                      t.column(e).search(a || "", !1, !1);
                    }),
                    t.table().draw();
                }),
                $(s).on("click", function(e) {
                  e.preventDefault(),
                    $(a)
                      .find(".kt-input")
                      .each(function(e) {
                        $(this).val(""),
                          t
                            .column($(this).data("col-index"))
                            .search("", !1, !1);
                      }),
                    t.table().draw();
                });
          }
          "Actions" !== this.title() && $(e).appendTo($("<th>").appendTo(a));
        });
      var n = function() {
        e.api()
          .columns()
          .every(function() {
            this.responsiveHidden()
              ? $(a)
                  .find("th")
                  .eq(this.index())
                  .show()
              : $(a)
                  .find("th")
                  .eq(this.index())
                  .hide();
          });
      };
      n(),
        (window.onresize = n),
        // Filter
        $(".kt-input").bind("keyup change", function() {
          $("#filter_search").trigger("click");
        }),
        $("#filter_search").trigger("click"),
        $(".datepicker-today-filter")
          .datepicker({
            format: "dd/mm/yyyy"
          })
          .datepicker("setDate", "now"),
        $(".datepicker-filter").datepicker({
          format: "dd/mm/yyyy"
        });
    },
    checkPermission: function(method) {
      return checkPermission.isCanAccess(method);
    }
  },
  mounted() {
    this.getDatatables();
  }
};
</script>
